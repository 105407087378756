import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import StyledReviewCard from "../components/reviews/reviewcard"

const Reviews = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location}>
      <SEO title={siteTitle} />
      {posts.map(({ node }) => {
        return <StyledReviewCard node={node}></StyledReviewCard>
      })}
    </Layout>
  )
}

export default Reviews

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/reviews.*.md$/" } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            author
            website
            date(formatString: "MMMM DD, YYYY")
            rating
            abstract
            tags
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 500, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`
