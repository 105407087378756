import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import RatingComponent from "../shared/ratings"
import { useSpring, animated } from "react-spring"

const ReviewCard = ({ node, className }) => {
  const [{ width, y, color }, set] = useSpring(() => ({
    config: { duration: 700 },
    width: 0,
    y: 100,
    color: "#fff",
  }))
  const featuredImg =
    node.frontmatter.thumbnail === null
      ? null
      : node.frontmatter.thumbnail.childImageSharp.fluid
  return (
    <Link
      to={node.fields.slug}
      className="my-8"
      onMouseEnter={() => set({ width: 100, y: 0 })}
      onMouseLeave={() => set({ width: 0, y: 100 })}
      onTouchStart={() => set({ width: 100, y: 0 })}
      onTouchEnd={() => set({ width: 0, y: 100 })}
    >
      {/* <div className=""> */}
      <div className="flex overflow-hidden  my-4 ">
        <div className="w-full flex flex-col rounded  leading-normal">
          <div className=" font-bold text-xl md:text-2xl ">
            {node.frontmatter.title}
          </div>
          <p className="text-sm  items-center">{node.frontmatter.author}</p>
        </div>
        <animated.div
          style={{ transform: y.interpolate(v => `translateY(${v}%`) }}
          className="w-2/3 text-right"
        >
          <AnimatedRatingComponent
            size="15px"
            rating={node.frontmatter.rating}
          ></AnimatedRatingComponent>
          <p className=""> {node.frontmatter.date}</p>
          {/* <ul className="italic ">
            {node.frontmatter.tags.map(t => (
              <li class="mr-2">{t},</li>
            ))}
          </ul> */}
        </animated.div>
      </div>
      <AnimatedBackgroundImage
        style={{ width: width.interpolate(v => `${v}%`) }}
        className="overflow-hidden h-3  text-black  rounded my-0 rounded"
        fluid={featuredImg === null ? "" : featuredImg}
      ></AnimatedBackgroundImage>
      {/* </div> */}
    </Link>
  )
}

const AnimatedBackgroundImage = animated(BackgroundImage)
const AnimatedRatingComponent = animated(RatingComponent)

const StyledReviewCard = styled(ReviewCard)`
  /* display: flex; */
  overflow: hidden;
  z-index: 1;
  position: relative;
  /* flex-direction: row; */
  background-repeat: no-repeat;
  border-radius: 0.25rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  width: 100%;
  /* You should set a background-size as the default value is "cover"! */
  background-size: cover;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
`

export default StyledReviewCard
